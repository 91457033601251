import { MouseEventHandler } from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { AbstractButton } from '../../atoms'
import styles from './HamburgerButton.module.css'

interface HamburgerButtonProps {
	handleToggleNavigation: MouseEventHandler
	isDropdownVisible: boolean
}

const beforeClasses =
	'before:absolute before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:h-[3px] before:w-5 before:bg-black before:rounded-3xl'
const afterClasses =
	'after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-[3px] after:w-5 after:bg-black after:rounded-3xl'

export const HamburgerButton = ({
	handleToggleNavigation,
	isDropdownVisible,
}: HamburgerButtonProps) => {
	return (
		<motion.div
			className="flex sm:-order-1 md:hidden lg:hidden"
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
				transition: {
					type: 'tween',
					duration: 0.3,
					delay: 0.15,
				},
			}}
		>
			<AbstractButton
				className="h-12 w-12 p-1"
				onClick={handleToggleNavigation}
				aria-label={`${isDropdownVisible ? 'Close' : 'Open'} navigation`}
			>
				<span
					className={clsx(
						'border-gray-05 relative inline-block h-full w-full rounded-full border-[3px]',
						beforeClasses,
						afterClasses,
						styles.hamburger,
						isDropdownVisible && styles.hamburgerActive,
						isDropdownVisible
							? 'before:mt-0 before:rotate-45 after:mt-0 after:-rotate-45'
							: 'before:-mt-[6px] after:mt-[6px]'
					)}
				>
					<span
						className={clsx(
							'absolute left-1/2 top-1/2 inline-block h-[3px] w-5 -translate-x-1/2 -translate-y-1/2 rounded-3xl bg-black',
							isDropdownVisible && 'opacity-0'
						)}
					/>
				</span>
			</AbstractButton>
		</motion.div>
	)
}
