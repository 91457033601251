import { Variants } from 'framer-motion'
import { AbstractButton, Container, ArrowRight } from 'ui'
import { NavigationType } from '../Navigation.types'
import DropdownData from './DropdownData'

type DropdownProps = Omit<NavigationType, 'title' | 'activePaths'>

export const variants: Record<'container' | 'item', Variants> = {
	container: {
		animate: {
			transition: {
				type: 'tween',
				staggerChildren: 0.1,
			},
		},
	},
	item: {
		initial: { opacity: 0, x: -10 },
		animate: {
			opacity: 1,
			x: 0,
			transition: {
				type: 'tween',
			},
		},
	},
}

export const Dropdown = ({
	homepageLabel,
	homepageUrl,
	linkGroups,
}: DropdownProps) => {
	return (
		<div className="absolute left-0 w-full bg-white sm:top-32 sm:flex sm:h-[calc(100%_-_3rem)] sm:flex-col sm:overflow-y-auto md:top-full lg:top-full">
			<Container isNested className="sm:flex sm:grow sm:flex-col sm:!px-0">
				{homepageLabel && (
					<AbstractButton
						href={homepageUrl}
						className="p_highlight text-primary flex w-fit items-center px-6 py-3 md:hidden lg:hidden"
					>
						{homepageLabel} <ArrowRight className="ml-2" />
					</AbstractButton>
				)}
				<DropdownData linkGroups={linkGroups} />
			</Container>
		</div>
	)
}
