import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { DomainLocales, lookups } from '@constants/language'

export const useLanguageSwitch = () => {
	const { pathname, asPath, query, locale, push, defaultLocale } = useRouter()

	const handleLanguageChange = useCallback(
		(item: string) => {
			let locale: string | undefined

			if (item === 'EN') {
				locale = lookups({
					language: defaultLocale as DomainLocales,
				}).englishLanguage
			} else {
				locale = defaultLocale
			}

			push({ pathname, query }, asPath, { locale })
		},
		[push, pathname, query, asPath, defaultLocale]
	)

	const domainLanguage = defaultLocale?.split('-')[0]?.toUpperCase() as string

	return {
		onLanguageChange: handleLanguageChange,
		domainLanguage,
		locale,
	}
}
