import { ComponentPropsWithoutRef, ReactNode } from 'react'
import clsx from 'clsx'

interface LayoutProps extends ComponentPropsWithoutRef<'main'> {
	children: ReactNode
	header: ReactNode
	footer: ReactNode
}

export const Layout = ({
	header,
	footer,
	children,
	className,
}: LayoutProps) => {
	return (
		<div className="flex min-h-screen flex-col">
			{header}
			<main
				className={clsx(
					'bg-gray-05 relative grow sm:pb-[72px] md:pb-28 lg:pb-[152px]',
					className
				)}
				id="main"
			>
				<div>{children}</div>
				<div id="panel" />
			</main>
			{footer}
		</div>
	)
}
