import { ComponentPropsWithoutRef, ReactNode } from 'react'
import clsx from 'clsx'
import { AbstractButton } from '../AbstractButton'
import { ChevronRight } from '../Icons'

interface NavLinkProps extends ComponentPropsWithoutRef<'div'> {
	href: string
	children: ReactNode
	tag?: string
}

export const NavLink = ({ href, children, tag, ...props }: NavLinkProps) => (
	<AbstractButton
		href={href}
		className={clsx(
			'p_highlight flex items-center whitespace-pre-wrap sm:px-6 sm:py-3 [&_svg]:hover:translate-x-1',
			props.className
		)}
	>
		<ChevronRight className="transition-transform duration-100" />
		<span>
			{children}
			{tag && (
				<span className="bg-primary micro_highlight ml-2 rounded-sm px-1.5 py-0.5 text-white">
					{tag}
				</span>
			)}
		</span>
	</AbstractButton>
)
