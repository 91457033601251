import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { Noop } from 'ui'
import IconButton from '../IconButton'
import { SocialIconsType } from './SocialIcons.data'

type SocialIconsProps = SocialIconsType & ComponentPropsWithoutRef<'div'>

const SocialIcons = ({ icons, theme, className }: SocialIconsProps) => {
	if (!icons?.length) {
		return <Noop />
	}

	return (
		<div className={clsx('flex w-full flex-wrap items-center', className)}>
			{icons.map((icon) => (
				<IconButton
					key={icon._uid}
					iconButton={icon}
					theme={theme}
					height={40}
					width={40}
				/>
			))}
		</div>
	)
}

export default SocialIcons
