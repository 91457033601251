import { ComponentPropsWithoutRef } from 'react'

export const ChevronRight = (props: ComponentPropsWithoutRef<'svg'>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.99987 18.9998C8.744 18.9998 8.488 18.9022 8.293 18.7069C7.90237 18.3162 7.90237 17.6834 8.293 17.2928L13.5874 11.9998L8.293 6.70703C7.90237 6.31641 7.90237 5.68359 8.293 5.29297C8.68362 4.90234 9.31644 4.90234 9.70706 5.29297L15.7071 11.293C16.0977 11.6836 16.0977 12.3164 15.7071 12.707L9.70706 18.707C9.51237 18.903 9.25612 18.9998 8.99987 18.9998Z"
			fill="currentColor"
		/>
	</svg>
)
