import { FC } from 'react'
import { Header, NavbarProps, Navigation } from 'ui'
import { HeaderWrapper } from 'ui/organism/Header'
import { Logo } from '@components/icons/Logo'
import { useDomainStore } from '@hooks/useDomainStore'

const Navbar: FC<NavbarProps> = ({ headerLinkGroups }) => {
	const { countryCode } = useDomainStore()

	return (
		<HeaderWrapper>
			<Header
				{...(countryCode === 'sportsaustria' && {
					customLogo: <Logo />,
				})}
			>
				<Navigation headerLinkGroups={headerLinkGroups} />
			</Header>
		</HeaderWrapper>
	)
}

export default Navbar
