import React, { ComponentPropsWithoutRef } from 'react'

export const Globe = (props: ComponentPropsWithoutRef<'svg'>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8Zm0 14.5c.231 0 .816-.225 1.488-1.512.274-.554.484-1.226.687-1.988h-4.35c.175.762.413 1.434.688 1.988.643 1.287 1.256 1.512 1.487 1.512Zm-2.422-5h4.844a14.453 14.453 0 0 0 0-3H9.578a14.453 14.453 0 0 0 0 3ZM14.175 9c-.203-.762-.413-1.434-.688-1.986C12.817 5.726 12.232 5.5 12 5.5c-.231 0-.844.226-1.488 1.514A9.003 9.003 0 0 0 9.825 9h4.35Zm1.756 1.5c.044.484.069.988.069 1.5s-.025 1.016-.069 1.5h2.394A6.42 6.42 0 0 0 18.5 12a6.42 6.42 0 0 0-.175-1.5h-2.394Zm-1.24-4.418c.44.801.79 1.796 1.018 2.918h2.06a6.528 6.528 0 0 0-3.078-2.918Zm-5.382 0A6.527 6.527 0 0 0 6.232 9h2.059c.228-1.122.578-2.117 1.018-2.918ZM5.5 12c0 .516.06 1.019.174 1.5H8.07A16.71 16.71 0 0 1 8 12c0-.512.025-1.016.069-1.5H5.674A6.52 6.52 0 0 0 5.5 12Zm12.269 3h-2.06c-.228 1.122-.578 2.116-1.018 2.919A6.532 6.532 0 0 0 17.769 15ZM8.29 15H6.232a6.532 6.532 0 0 0 3.077 2.919c-.44-.803-.79-1.797-1.018-2.919Z"
			fill="currentColor"
		/>
	</svg>
)
