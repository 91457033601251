import { ReactNode } from 'react'
import { Container, AbstractButton } from 'ui'
import { Logo } from '../../atoms/Icons'
import { motion } from 'framer-motion'

interface HeaderProps {
	children?: ReactNode
	customLogo?: ReactNode
}

export const Header = ({ children, customLogo }: HeaderProps) => {
	return (
		<Container className="flex items-center">
			<motion.div
				className="sm:mx-auto md:mr-auto lg:mr-auto"
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
					transition: {
						type: 'tween',
						duration: 0.3,
					},
				}}
			>
				<AbstractButton
					href="/"
					aria-label="Logo"
					className="flex h-12 items-center"
				>
					{customLogo || <Logo />}
				</AbstractButton>
			</motion.div>
			{children}
		</Container>
	)
}
