import { z } from 'zod'
import clsx from 'clsx'
import { IconButtonSchema } from './IconButton.schema'
import { OptimizedImage } from 'ui'
import { AbstractButton } from '../../atoms'

export interface ButtonProps {
	iconButton: z.TypeOf<typeof IconButtonSchema>
	theme?: 'light' | 'dark'
	class?: string
	height?: number
	width?: number
}

const IconButton = ({
	iconButton,
	theme,
	width,
	height,
	class: className,
	...props
}: ButtonProps) => {
	const renderIcon = () => {
		if (!!theme && theme === 'light') {
			return (
				<OptimizedImage
					alt="icon"
					src={iconButton.light_icon.filename}
					width={width || 32}
					height={height || 32}
				/>
			)
		} else {
			return (
				<OptimizedImage
					alt="icon"
					src={iconButton.dark_icon.filename}
					width={width || 32}
					height={height || 32}
				/>
			)
		}
	}

	return (
		<AbstractButton
			href={iconButton.link}
			aria-label="Social icon"
			title={iconButton.link}
			className={clsx(
				'flex items-center justify-center transition-opacity hover:opacity-80 active:opacity-90',
				className && className
			)}
			{...props}
		>
			{renderIcon()}
		</AbstractButton>
	)
}

export default IconButton
