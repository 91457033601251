import { useState } from 'react'
import { useRouter } from 'next/router'
import { NavigationType } from '../../Navigation/Navigation.types'
import NavigationAccordion from './NavigationAccordion'

type MobileNavigationProps = {
	navigationData: Array<NavigationType>
}

const MobileNavigation = ({
	navigationData,
}: MobileNavigationProps) => {
	const { push } = useRouter()
	const [opened, setOpened] = useState<number>(0)

	return (
		<div className="flex h-full w-full flex-col">
			{navigationData.map((data, idx) => {
				const handleClick = () => {
					if (opened === idx) {
						push(data.homepageUrl || '')
					} else {
						setOpened(idx)
					}
				}

				return (
					<NavigationAccordion
						key={data.title}
						linkGroups={data.linkGroups}
						title={data.title}
						isOpened={opened === idx}
						opened={opened}
						index={idx}
						handleClick={handleClick}
					/>
				)
			})}
		</div>
	)
}

export default MobileNavigation
