import { ComponentPropsWithoutRef } from 'react'
import { Variants, motion } from 'framer-motion'
import clsx from 'clsx'
import { AbstractButton, ArrowRight, Plus } from '../../../../atoms'
import { LinkGroup } from '../../Navigation.types'
import DropdownData from '../../Dropdown/DropdownData'

const variants: Variants = {
	hide: { maxHeight: 'calc(0% - -80px)' },
	show: {
		maxHeight: 'calc(100% - 160px)',
	},
}

type NavigationAccordionProps = {
	linkGroups: Array<LinkGroup>
	isOpened: boolean
	opened: number
	index: number
	title: string
	titleStyle?: string
	handleClick: () => void
} & ComponentPropsWithoutRef<'div'>

const NavigationAccordion = ({
	linkGroups,
	isOpened,
	opened,
	index,
	title,
	titleStyle,
	handleClick,
	...props
}: NavigationAccordionProps) => {
	return (
		<motion.div
			className={clsx(
				'flex max-h-[calc(100%_-_160px)] w-full flex-col',
				props.className
			)}
			variants={variants}
			animate={isOpened ? 'show' : 'hide'}
			initial="hide"
			transition={{
				type: 'tween',
				duration: 0.2,
			}}
		>
			<AbstractButton
				className={clsx(
					'z-default flex w-full items-center rounded-sm bg-white/[0.64] py-6 pl-6 pr-4 text-left backdrop-blur transition-colors',
					isOpened ? 'text-primary gap-x-2' : 'justify-between'
				)}
				onClick={handleClick}
			>
				<p className={clsx('word_break', titleStyle || 'subtitle_highlight')}>
					{title}
				</p>
				{isOpened ? <ArrowRight /> : <Plus />}
			</AbstractButton>
			<div
				className={clsx(
					'flex flex-col overflow-y-auto',
					index === 0 && index === opened && '-mb-40 -mt-20 pb-40 pt-20',
					index === 1 && index === opened && '-mb-20 -mt-40 pb-20 pt-40',
					index === 2 && index === opened && '-mt-60 pt-60'
				)}
			>
				<DropdownData
					linkGroups={linkGroups}
					className={clsx(isOpened && 'mt-auto')}
				/>
			</div>
		</motion.div>
	)
}

export default NavigationAccordion
