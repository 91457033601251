import { ReactNode, useState } from 'react'
import throttle from 'lodash.throttle'
import clsx from 'clsx'
import { useEventListener } from 'common'

type HeaderWrapperProps = {
	children: ReactNode
}

export function HeaderWrapper({ children }: HeaderWrapperProps) {
	const [hasScrolled, setHasScrolled] = useState(false)

	useEventListener(
		'scroll',
		throttle(() => {
			const { scrollTop } = document.documentElement

			setHasScrolled(!!scrollTop)
		}, 200)
	)

	return (
		<header
			className={clsx(
				'js-header z-navigation sticky left-0 top-0 w-full bg-white sm:py-3 md:py-5 lg:py-5',
				hasScrolled && 'shadow-magical'
			)}
		>
			{children}
		</header>
	)
}
