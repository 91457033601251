import Navbar from '@components/common/Navbar'
import Footer from '@components/common/Footer'
import { Layout, NavigationData } from 'ui'
import { ReactElement } from 'react'
import { CompanyData } from 'ui/molecules/Navigation/Navigation.types'

export const getLayout = (
	page: ReactElement<{
		companyData: CompanyData
		navigationData: NavigationData
	}>
) => {
	const { navigationData, companyData } = page.props ?? {}
	const { header_link_groups, footer_main_links, footer_bottom_links } =
		navigationData ?? {}

	return (
		<Layout
			header={<Navbar headerLinkGroups={header_link_groups} />}
			footer={
				<Footer
					mainLinks={footer_main_links}
					bottomLinks={footer_bottom_links}
					companyData={companyData}
				/>
			}
		>
			{page}
		</Layout>
	)
}
