import { useEffect } from 'react'

type EventCallback<K extends keyof DocumentEventMap> = (
	event: DocumentEventMap[K]
) => unknown | EventListenerOrEventListenerObject

interface EventDispatcherLike {
	addEventListener<K extends keyof DocumentEventMap>(
		type: K,
		listener: (event: DocumentEventMap[K]) => unknown,
		options?: boolean | AddEventListenerOptions
	): void
	addEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject,
		options?: boolean | AddEventListenerOptions
	): void
	removeEventListener<K extends keyof DocumentEventMap>(
		type: K,
		listener: (event: DocumentEventMap[K]) => unknown,
		options?: boolean | EventListenerOptions
	): void
	removeEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject,
		options?: boolean | EventListenerOptions
	): void
}

export const useEventListener = <K extends keyof DocumentEventMap>(
	eventName: K,
	callback: EventCallback<K>,
	/**
	 * if the value passed is a `ref.current`
	 * then the callback will not properly update
	 * this is intended. If you wish to use a ref of a component
	 * then set and store it with a `useState`.
	 */
	target?: EventDispatcherLike,
	options?: AddEventListenerOptions,
	deps: Array<unknown> = []
): void => {
	// https://github.com/facebook/react/issues/14476#issuecomment-471199055
	// JSON.stringify comes from a suggestion made by Dan Abramov
	// on how to better handle dynamic objects when they have to be part
	// of a dependency array. Since our configuration contains primitive values
	// and is single level, then using JSON.stringify _should_ be plenty quick
	// for our use case
	const stringifiedOptions = JSON.stringify(options)

	useEffect(() => {
		const getTarget = target || window

		getTarget.addEventListener(eventName, callback, options)

		return () => {
			getTarget.removeEventListener(eventName, callback, options)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventName, target, stringifiedOptions, ...deps])
}
