import { ComponentPropsWithoutRef, useRef } from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { ElementType, AbstractButton } from '../../atoms/AbstractButton'

interface PillProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onClick'> {
	items: [string, string] | [string, string, string]
	active: string
	onClick: (item: string) => void
	theme?: 'primary' | 'light' | 'monochrome'
}

export const Pill = ({
	items,
	active,
	onClick,
	theme = 'primary',
	className,
}: PillProps) => {
	const { current: itemsCurrent } = useRef<Array<ElementType>>([])

	const activeElement = itemsCurrent.find((item) => item.name === active)

	const isPrimary = theme === 'primary'
	const isMonochrome = theme === 'monochrome'
	const isLight = theme === 'light'

	return (
		<div
			className={clsx(
				'rounded-full p-1',
				isPrimary && 'bg-primary-alpha/[0.08]',
				isLight && 'bg-[#7D050029]',
				isMonochrome && 'bg-gray-05',
				className
			)}
		>
			<div className="relative flex h-full">
				<motion.span
					className={clsx(
						'absolute inline-block h-full rounded-full',
						isPrimary ? 'bg-primary' : 'bg-white'
					)}
					animate={{
						x: activeElement?.offsetLeft || 0,
					}}
					{...(activeElement && {
						style: {
							x: activeElement.offsetLeft,
							width: `${activeElement.clientWidth}px`,
						},
					})}
				/>
				{items.map((item, index) => {
					const isActive = active === item

					return (
						<AbstractButton
							suppressHydrationWarning
							key={item}
							ref={(element) => {
								itemsCurrent[index] = element as ElementType
							}}
							name={item}
							onClick={() => onClick(item)}
							className={clsx(
								'p_highlight z-default rounded-full px-4 py-2 uppercase transition-colors disabled:pointer-events-none',
								isActive &&
									!activeElement &&
									(isPrimary ? 'bg-primary' : 'bg-white'),
								isPrimary && 'text-primary disabled:text-white',
								isLight && 'disabled:text-primary text-white',
								isMonochrome && 'text-gray-70 disabled:text-primary'
							)}
							disabled={isActive}
						>
							{item}
						</AbstractButton>
					)
				})}
			</div>
		</div>
	)
}
