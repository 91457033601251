import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { NavLink } from '../../../atoms'
import { LinkGroup } from '../Navigation.types'
import { variants } from './Dropdown'

interface DropdownDataProps extends ComponentPropsWithoutRef<'div'> {
	linkGroups: Array<LinkGroup>
}

const DropdownData = ({ linkGroups, ...props }: DropdownDataProps) => (
	<motion.div
		variants={variants.container}
		initial="initial"
		animate="animate"
		className={clsx(
			'relative flex justify-between sm:flex-grow sm:flex-col md:items-start md:py-8 lg:items-start lg:py-8',
			'before:bg-gray-05 before:absolute before:left-4 before:top-0 before:h-0.5 before:w-[calc(100%_-_1rem)] sm:before:content-none',
			props.className
		)}
	>
		{linkGroups.map(({ title, links }, index) => {
			const isHighlighted = linkGroups.length - 1 === index
			return (
				<motion.div
					variants={variants.item}
					key={title}
					className={clsx(
						'sm:mb-2 last:sm:mb-0 last:sm:pb-6 md:py-8 lg:py-8',
						linkGroups.length > 2 &&
							'md:mx-auto first:md:mx-0 last:md:mx-0 lg:mx-auto first:lg:mx-0 last:lg:mx-0',
						isHighlighted
							? 'sm:mt-8 sm:pt-6 md:mx-0 md:px-10 lg:mx-0 lg:px-10'
							: 'md:mx-auto md:px-4 lg:mx-auto lg:px-4',
						isHighlighted &&
							'bg-gray-05 w-full sm:flex-auto md:max-w-[294px] lg:max-w-[345px]'
					)}
				>
					<h4 className="p_highlight text-gray-55 uppercase sm:px-6 sm:py-3 md:mb-6 lg:mb-6">
						{title}
					</h4>
					<motion.ul
						variants={variants.container}
						initial="initial"
						animate="animate"
						className="flex flex-col items-start"
					>
						{links.map(({ url, text, tag }) => (
							<motion.li
								variants={variants.item}
								key={text}
								className="last:mb-0 md:mb-4 lg:mb-4"
							>
								<NavLink href={url} tag={tag}>
									{text}
								</NavLink>
							</motion.li>
						))}
					</motion.ul>
				</motion.div>
			)
		})}
	</motion.div>
)

export default DropdownData
